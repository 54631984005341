<template>
  <div>
    <v-card class="mt-2">
      <v-card-title class="d-flex justify-items">
        {{ title }}<v-spacer />
        <v-switch inset v-model="showHistory" label="history"></v-switch
      ></v-card-title>
      <v-data-table
        :headers="headersActivities"
        :items="activitesFiltered"
        :items-per-page="-1"
        :search="search"
        :loading="loadingActivities"
        sort-by="startDate"
        sort-desc
      >
        <template v-slot:item.person="{ item }">
          <PersonPicker
            group="person"
            @input="updateActivity(item)"
            v-model="item.person"
          />
        </template>
        <template v-slot:item.device.name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                outlined
                :to="{
                  name: 'InventoryDevice',
                  params: { id: item.device.id },
                }"
                >{{ item.device.name }}
              </v-chip>
            </template>
            <span>Dieses Gerät bearbeiten</span>
          </v-tooltip>
        </template>
        <template v-slot:item.startDate="{ item }">
          <DatePicker
            color="info"
            @input="updateActivity(item)"
            v-model="item.startDate"
          />
        </template>
        <template v-slot:item.dueDate="{ item }">
          <DatePicker
            color="error"
            @input="updateActivity(item)"
            v-model="item.dueDate"
          />
        </template>
        <template v-slot:item.endDate="{ item }">
          <DatePicker
            color="success"
            @input="updateActivity(item)"
            v-model="item.endDate"
          />
        </template>
        <template v-slot:item.comment="{ item }">
          <v-edit-dialog
            cancel-text="Abbrechen"
            save-text="Speichern"
            large
            :return-value.sync="item.comment"
            @save="updateActivity(item)"
          >
            <v-tooltip top v-if="item.comment">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-comment-text</v-icon>
              </template>
              <span>{{ item.comment }}</span>
            </v-tooltip>
            <v-icon v-else>mdi-comment-plus</v-icon>
            <template v-slot:input>
              <v-textarea v-model="item.comment"></v-textarea>
            </template>
          </v-edit-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="red"
                @click="trash(item.id)"
                ><v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Diesen Ausleiheintrag löschen</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mt-2">
      <v-card-title> Ausleihbare Geräte </v-card-title>
      <v-data-table
        :headers="headersDevices"
        :items="devicesFiltered"
        :items-per-page="-1"
        :search="search"
        :loading="loadingDevices"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                color="success"
                @click="loanDevice(item.id)"
                ><v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Dieses Gerät ausleihen</span>
          </v-tooltip>
        </template>
        <template v-slot:item.name="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                outlined
                :to="{ name: 'InventoryDevice', params: { id: item.id } }"
                >{{ item.name }}
              </v-chip>
            </template>
            <span>Dieses Gerät bearbeiten</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import DatePicker from '@/components/DatePicker';
import PersonPicker from '@/components/PersonPicker';
export default {
  name: 'InventoryLoans',
  props: ['search'],
  components: { DatePicker, PersonPicker },
  data() {
    return {
      devices: [],
      activities: [],
      loadingDevices: false,
      loadingActivities: false,
      showHistory: false,
      headersActivities: [
        { text: '', value: 'comment' },
        { text: 'Typ', value: 'device.deviceType.description' },
        { text: 'Modell', value: 'device.brandModel.description' },
        { text: 'Name', value: 'device.name' },
        { text: 'Person', value: 'person' },
        { text: 'Anfang', value: 'startDate' },
        { text: 'Fälligkeit', value: 'dueDate' },
        { text: 'Rückgabe', value: 'endDate' },

        { text: '', value: 'actions', sortable: false },
      ],
      headersDevices: [
        { text: 'Typ', value: 'deviceType.description' },
        { text: 'Modell', value: 'brandModel.description' },
        { text: 'Name', value: 'name' },
        { text: 'Seriennummer', value: 'serialNumber' },
        { text: 'Kommentar', value: 'comment' },
        { text: '', value: 'actions', sortable: false },
      ],
    };
  },
  async mounted() {
    await this.loadAll();
  },
  computed: {
    title() {
      if (this.showHistory) {
        return 'Ausleihen';
      } else {
        return 'Momentan ausgeliehene Geräte';
      }
    },
    activitesFiltered() {
      if (this.showHistory) {
        return this.activities;
      } else {
        return this.activities.filter((activity) => !activity.endDate);
      }
    },
    devicesFiltered() {
      return this.devices.filter(
        (el) =>
          !this.activities
            .filter((el) => !el.endDate)
            .map((el) => el.device.id)
            .includes(el.id)
      );
    },
  },
  methods: {
    async loadAll() {
      await this.loadDevices();
      await this.loadActivities();
    },
    async updateActivity(activity) {
      await this.apiPut({ resource: 'inventory/activity', data: activity });
      this.$root.showSuccess('erfolgreich gespeichert');
    },
    async loadDevices() {
      this.loadingDevices = true;
      this.devices = await this.apiList({
        resource: 'inventory/device',
        query: 'loanable',
      });
      this.loadingDevices = false;
    },
    async loadActivities() {
      this.loadingActivities = true;
      this.activities = await this.apiList({
        resource: 'inventory/activity',
        query: 'loaned',
      });
      this.loadingActivities = false;
    },
    async deleteActivity(id) {
      if (
        await this.$root.confirm({
          message: 'Soll diese Aktivität gelöscht werden?',
          color: 'error',
          icon: 'mdi-trash-can',
        })
      ) {
        await this.apiDelete({ resouce: 'inventory/activity', id: id });
        await this.loadAll();
      }
    },
    async loanDevice(id) {
      if (
        await this.$root.confirm({
          message: 'Soll dieser Inventar-Eintrag ausgeliehen werden?',
          color: 'info',
          icon: 'mdi-hand-extended',
        })
      ) {
        await this.apiPost({
          resource: 'inventory/activity',
          data: { device: id, service: false },
        });
        await this.loadAll();
      }
    },
    async trash(id) {
      if (
        await this.$root.confirm({
          message: `Soll dieser Reparatureintrag gelöscht werden?`,
          color: 'red',
          icon: 'mdi-trash-can',
        })
      ) {
        await this.apiDelete({
          resource: 'inventory/activity',
          id: id,
        });
        this.loadActivities();
      }
    },
  },
};
</script>
